import React from 'react';
import PageHeader from './PageHeader';
import AboutImg from '../img/about-img.png'
import Best_1 from '../img/nominations/nominations_1.png';
import Best_2 from '../img/nominations/nominations_2.png';
import Best_3 from '../img/nominations/nominations_3.png';
import Best_4 from '../img/nominations/nominations_4.png';
import Best_5 from '../img/nominations/nominations_5.png';
import Best_6 from '../img/nominations/nominations_6.png';
import Best_7 from '../img/nominations/nominations_7.png';
import Best_8 from '../img/nominations/nominations_8.png';
import Best_9 from '../img/nominations/nominations_9.png';
import Best_10 from '../img/nominations/nominations_10.png';

const nominationsData = [
  {
    title: 'Лучший международный фильм',
    icon: Best_1,
  },
  {
    title: 'Лучший дебютный фильм',
    icon: Best_2,
  },
  {
    title: 'Лучший актер',
    icon: Best_3,
  },
  {
    title: 'Лучшая актриса',
    icon: Best_4,
  },
  {
    title: 'Лучший телевизионный проект',
    icon: Best_5,
  },
  {
    title: 'Лучший сериал',
    icon: Best_6,
  },
  {
    title: 'Лучший сценарист',
    icon: Best_7,
  },
  {
    title: 'Лучший режиссер',
    icon: Best_8,
  },
  {
    title: 'Лучший композитор',
    icon: Best_9,
  },
  {
    title: 'Лучший продюсер',
    icon: Best_10,
  }
];

const AboutUsPage = () => {
  return (
    <>
      <PageHeader title="О премии" breadcrumb="О премии" />

      <div className="position-wrapper">
      <section className="new-section">
        <div className="content-wrapper">
          <div className="image-container">
            <img src={AboutImg} alt="Cinematography" className="main-image" />
          </div>
          <div className="text-container">
            <h2 className="section-title">О премии «Silver Phoenix Awards»</h2>
            <p className="description">
            Международная премия в области кино и телевидения «Silver Phoenix Awards» — это событие, которое с 2019 года занимает особое место в мире кинематографа и телевидения. Учрежденная Ассоциацией продюсеров кино и телевидения, премия была создана с целью признания и поддержки самых значимых достижений в индустрии. За эти годы «Silver Phoenix Awards» стала одной из самых престижных международных наград, привлекая внимание профессионалов и ценителей искусства со всего мира.
            </p>
          </div>
        </div>
      </section>

      <section className="nominations-section">
      <h2 className='title'>Номинации Silver Phoenix Awards</h2>
      <div className="nominations-home-grid">
        {nominationsData.map((nomination, index) => (
          <div key={index} className="nomination-card">
            <img src={nomination.icon} alt={nomination.title} className="nomination-icon" />
            <h3 className="nomination-title">{nomination.title}</h3>
            <ul className="criteria-list">
            </ul>
          </div>
        ))}
      </div>
    </section>

      </div>
      <section className="background-section">
        <div className="centered-content">
        <h2 className="centered-title">Ежегодная премия Silver Phoenix Awards проходит 3 июня и является значимым событием в мире кино и телевидения, объединяющим лучших профессионалов отрасли.</h2>
        </div>
      </section>
      <div className="wrapper">
      <div class="about-section">
      <h2>С первых лет своего существования</h2>
      <p>«Silver Phoenix Awards» зарекомендовала себя как премия, которая символизирует высочайший профессионализм и новаторство. Ежегодно на церемонию приезжают самые талантливые режиссеры, актеры, сценаристы, продюсеры и другие мастера кино и телевидения, чьи работы не только вдохновляют, но и изменяют восприятие искусства на глобальном уровне. Победители премии получают признание не только коллег, но и широкой мировой аудитории.</p>
      <h2>Особенности</h2>
      <p>Особенностью «Silver Phoenix Awards» является её международный характер. В премии участвуют работы из самых разных уголков планеты, что придает премии уникальный статус. Это событие позволяет увидеть, как кино и телевидение развиваются в различных культурах, объединяя их через универсальный язык визуального искусства. «Silver Phoenix Awards» демонстрирует, что искусство не знает границ и может говорить на всех языках мира.</p>
      <p><p>Премия охватывает все аспекты кинематографического и телевизионного искусства, предлагая широкий спектр номинаций, отражающих многообразие и сложность процессов создания фильмов и телепрограмм. От режиссуры и сценарного мастерства до работы с актерами и созданием визуальных эффектов — каждая номинация подчеркивает уникальные таланты и достижения участников. Такой подход позволяет «Silver Phoenix Awards» оставаться актуальной и поддерживать стремление к совершенству в индустрии.</p></p>
      <p>Отбор членов жюри для «Silver Phoenix Awards» — это тщательно выверенный процесс, направленный на обеспечение высочайших стандартов оценивания. В состав жюри входят только самые опытные и уважаемые профессионалы кино и телевидения, которые имеют международное признание в индустрии. Каждый кандидат проходит через несколько этапов отбора, включая проверку профессиональной репутации и достижений. Жюри анализирует работы участников по множеству критериев, обеспечивая объективность и справедливость в принятии решений.</p>
      <h2>Престиж</h2>
      <p>Быть членом жюри «Silver Phoenix Awards» — это не просто профессиональное признание, но и высшая честь, открывающая новые горизонты для каждого специалиста. Участие в судейской коллегии предоставляет уникальную платформу для обмена опытом и творческими идеями с коллегами со всего мира. Судейство на таком высоком уровне укрепляет личный бренд и способствует международному признанию, что может привести к новым предложениям и проектам.</p>
      <p>Члены жюри «Silver Phoenix Awards» не только оценивают работы участников, но и вносят свой вклад в развитие мировой кино- и телеиндустрии. Признание лучших проектов и поддержка инновационных решений помогают формировать будущее индустрии. Этот опыт становится важной частью профессионального пути каждого судьи, вдохновляя их на дальнейшие творческие достижения.</p>
      <h2>Церемония</h2>
      <p>Церемония награждения «Silver Phoenix Awards» — это не просто вручение наград, но и настоящее культурное событие, которое становится важной частью жизни каждого участника. В этот день чествуют победителей, обсуждают будущее кино и телевидения, делятся новыми идеями и проектами. С каждым годом премия продолжает расти и расширять свои горизонты, оставаясь на передовой линии инноваций в индустрии.</p>
      <p>Быть лауреатом Международной премии в области кино и телевидения «Silver Phoenix Awards» — это не только почетное звание, но и подтверждение высочайшего уровня профессионализма и таланта. Каждый победитель премии входит в число избранных, чьи работы и достижения признаны мировым сообществом за их вклад в развитие кино- и телеискусства. Лауреаты «Silver Phoenix Awards» становятся частью престижного клуба, где каждый новый успех усиливает их влияние на индустрию и вдохновляет будущее поколение творцов.</p>
      <h2>Члены жюри</h2>
      <p>Члены жюри «Silver Phoenix Awards» играют ключевую роль в поддержании высокого уровня этой премии. Отбираемые из числа самых выдающихся профессионалов, они несут ответственность за объективность и справедливость в оценке работ. Каждый член жюри приносит свой уникальный опыт и экспертные знания, что делает процесс отбора лауреатов максимально тщательным и честным. Признание со стороны коллег и возможность влиять на развитие мировой индустрии — это высшая награда для каждого, кто удостоен чести стать судьей на «Silver Phoenix Awards».</p>
        <p>Вместе лауреаты и члены жюри создают уникальное сообщество, которое формирует будущее кино и телевидения, поддерживая самые смелые и инновационные проекты, открывая новые таланты и ставя высокие стандарты качества в искусстве.</p>
      </div>

        </div>
      
    </>
  );
};

export default AboutUsPage;
