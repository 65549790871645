import React from 'react';
import PageHeader from './PageHeader';

const Position = () => {
  return (
    <>
      <PageHeader title="Положение" breadcrumb="Положение" />

      <div className="position-wrapper">
        <section className="position-section">
          <div className="position-content-wrapper">
            <div className="position-text-container">
              <h2 className="position-section-title">Положение Международной премии в области кино и телевидения «Silver Phoenix Awards»</h2>
              
              <p className="position-description">
                <h4>1. Общие положения</h4>
                Международная премия в области кино и телевидения «Silver Phoenix Awards» — это ежегодное событие, организуемое Ассоциацией продюсеров кино и телевидения с целью признания и поощрения выдающихся достижений в индустрии.
                Премия проводится ежегодно в июне и охватывает как кино, так и телевизионные номинации. Основная задача премии — поддержка и развитие профессионализма, новаторства и художественного мастерства в области кино и телевидения.
              </p>

              <p className="position-description">
                <h4>2. Сроки проведения</h4>
                Премия «Silver Phoenix Awards» проводится ежегодно 3 июня. Прием заявок на участие в конкурсе начинается за месяц до церемонии награждения и длится до 31 мая включительно.
              </p>

              <p className="position-description">
                <h4>3. Процесс подачи заявок</h4>
                Участники могут подать свои работы на рассмотрение жюри, заполнив заявку на официальном сайте премии. Работы, поданные после установленного срока, не рассматриваются. Все заявки должны соответствовать установленным критериям и требованиям, описанным в этом положении.
              </p>

              <p className="position-description">
                <h4>4. Номинации</h4>
                Премия включает следующие номинации:
                <ul>
                  <li><b>Лучший международный фильм:</b> Оценивается за художественное исполнение, оригинальность, международное признание и культурное влияние на мировую аудиторию.</li>
                  <li><b>Лучший дебютный фильм:</b> Присуждается за оригинальность режиссерского видения, техническое мастерство и новаторский подход в первом проекте режиссера.</li>
                  <li><b>Лучший актер:</b> Оцениваются актерские способности, глубина персонажа, эмоциональная выразительность и влияние на зрителя.</li>
                  <li><b>Лучшая актриса:</b> Награждается за выдающееся исполнение роли, способность передать сложные эмоциональные состояния и воздействовать на аудиторию.</li>
                  <li><b>Лучший телевизионный проект:</b> Признается за новаторство, качество производства, зрительский отклик и вклад в развитие телевидения.</li>
                  <li><b>Лучший сериал:</b> Оценивается за целостность повествования, качество сценария, актёрскую игру и популярность среди зрителей.</li>
                  <li><b>Лучший сценарист:</b> Награда за оригинальный, качественный сценарий, который раскрывает глубокие темы и создает эмоциональную вовлеченность.</li>
                  <li><b>Лучший режиссер:</b> Оценивается за мастерство координации всех элементов производства, творческое видение и влияние на зрительское восприятие.</li>
                  <li><b>Лучший композитор:</b> Награждается за оригинальную музыку, которая усиливает атмосферу и эмоциональное воздействие фильма или сериала.</li>
                  <li><b>Лучший продюсер:</b> Признается за эффективное управление проектом, организационные способности, привлечение талантов и успешное завершение проекта.</li>
                </ul>
              </p>

              <p className="position-description">
                <h4>5. Жюри и критерии отбора</h4>
                Жюри премии «Silver Phoenix Awards» формируется из числа ведущих профессионалов кино- и телеиндустрии. Члены жюри отбираются Ассоциацией продюсеров кино и телевидения по следующим критериям:
                <ul>
                  <li>Профессиональный опыт — минимум три года активной работы в индустрии.</li>
                  <li>Репутация и достижения — положительная репутация и значимые достижения.</li>
                  <li>Объективность и независимость — способность объективно оценивать работы.</li>
                  <li>Инновационное мышление — вклад в развитие индустрии.</li>
                  <li>Международное признание — работы, признанные на международном уровне.</li>
                </ul>
              </p>

              <p className="position-description">
                <h4>6. Церемония награждения</h4>
                Церемония проводится в престижном месте и объявляется за месяц до мероприятия. Победители получают статуэтку «Silver Phoenix».
              </p>

              <p className="position-description">
                <h4>7. Заключительные положения</h4>
                Организаторы оставляют за собой право вносить изменения в положение, информируя участников через официальные каналы. Участие в «Silver Phoenix Awards» — это шанс получить признание среди ведущих профессионалов кино и телевидения.
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Position;
