import React from 'react';
import PageHeader from './PageHeader';

const WinnersPage = () => {
  return (
    <>
      <PageHeader title="Победители" breadcrumb="Победители" />
      <div className="winners-page">
        <h1 className="winners-title">Победители Silver Phoenix Awards (2019-2024)</h1>

        <div className="winners-year-section">
          <h2 className="winners-year-title">2019</h2>
          <ul className="winners-list">
            <li className="winners-item"><strong>Лучший международный фильм:</strong> «Ветер перемен» (Казахстан) — за уникальное сочетание культуры и современности, высокое художественное исполнение.</li>
            <li className="winners-item"><strong>Лучший дебютный фильм:</strong> «Начало» — Иван Петров (Россия) — за новаторский подход и техническое мастерство в первом проекте.</li>
            <li className="winners-item"><strong>Лучший актер:</strong> Андрей Романов («Одинокий путь») (Беларусь) — за глубину персонажа и эмоциональную выразительность.</li>
            <li className="winners-item"><strong>Лучшая актриса:</strong> Мадина Темирова («Желтая река») (Киргизия) — за яркое исполнение роли, затрагивающее глубокие чувства зрителей.</li>
            <li className="winners-item"><strong>Лучший телевизионный проект:</strong> «Дневник памяти» (Россия) — за новаторство и высокое качество производства, получившее отклик у зрителей.</li>
            <li className="winners-item"><strong>Лучший сериал:</strong> «Жизнь на грани» (Армения) — за целостность повествования и высокую актерскую игру.</li>
            <li className="winners-item"><strong>Лучший сценарист:</strong> Алина Саркисян (Азербайджан) — за оригинальный и захватывающий сценарий к фильму «Ночной зов».</li>
            <li className="winners-item"><strong>Лучший режиссер:</strong> Роман Ивашов (Молдова) — за режиссуру фильма «Сны о свободе», умело координируя все элементы кинопроизводства.</li>
            <li className="winners-item"><strong>Лучший композитор:</strong> Валерий Кравцов (Россия) — за уникальный саундтрек к фильму «Тени прошлого», усиливающий атмосферу фильма.</li>
            <li className="winners-item"><strong>Лучший продюсер:</strong> Оксана Кириллова (Россия) — за успешное продюсирование музыкального проекта «Ну-ка все вместе!» (телеканал «Россия 1).</li>
          </ul>
        </div>

        <div className="winners-year-section">
          <h2 className="winners-year-title">2020</h2>
          <ul className="winners-list">
            <li className="winners-item"><strong>Лучший международный фильм:</strong> «Песни дюн» (Узбекистан) — за уникальный культурный вклад и высокую художественность.</li>
            <li className="winners-item"><strong>Лучший дебютный фильм:</strong> «Зов тишины» — Оксана Леонтьева (Беларусь) — за впечатляющее режиссерское видение в первом проекте.</li>
            <li className="winners-item"><strong>Лучший актер:</strong> Тимур Алиев («Восход») (Казахстан) — за выразительную игру и эмоциональную глубину персонажа.</li>
            <li className="winners-item"><strong>Лучшая актриса:</strong> Татьяна Иванова («Зеркало») (Украина) — за выдающееся исполнение сложной роли, трогающее зрителей.</li>
            <li className="winners-item"><strong>Лучший телевизионный проект:</strong> «Мир вокруг нас» (Беларусь) — за новаторство и высокий зрительский отклик.</li>
            <li className="winners-item"><strong>Лучший сериал:</strong> «След огня» (Таджикистан) — за качество сценария и актёрскую игру, ставший хитом среди зрителей.</li>
            <li className="winners-item"><strong>Лучший сценарист:</strong> Светлана Орлова (Киргизия) — за сценарий к фильму «Под покровом ночи», привлекающий внимание к глубоким социальным проблемам.</li>
            <li className="winners-item"><strong>Лучший режиссер:</strong> Алиса Виноградова (Россия) — за режиссуру короткометражного фильма «Фингерфуд», отмеченного за эмоциональную насыщенность.</li>
            <li className="winners-item"><strong>Лучший композитор:</strong> Арман Садыков (Казахстан) — за оригинальный музыкальный подход к фильму «Свет сквозь облака».</li>
            <li className="winners-item"><strong>Лучший продюсер:</strong> Мария Захарова (Азербайджан) — за продюсирование успешного телевизионного проекта «На перекрестке».</li>
          </ul>
        </div>

        <div className="winners-year-section">
          <h2 className="winners-year-title">2021</h2>
          <ul className="winners-list">
            <li className="winners-item"><strong>Лучший международный фильм:</strong> «Тайны ветра» (Россия) — за выдающееся художественное исполнение и международное признание.</li>
            <li className="winners-item"><strong>Лучший дебютный фильм:</strong> «На пороге» — Анна Семенова (Молдова) — за оригинальность режиссерского подхода в дебютном проекте.</li>
            <li className="winners-item"><strong>Лучший актер:</strong> Михаил Кузьмин («Судьба») (Россия) — за сильную и трогательную актерскую работу.</li>
            <li className="winners-item"><strong>Лучшая актриса:</strong> Наталья Котова («Вдохновение») (Казахстан) — за эмоциональную насыщенность роли и потрясающее исполнение.</li>
            <li className="winners-item"><strong>Лучший телевизионный проект:</strong> «Тайна города» (Азербайджан) — за высокий зрительский отклик и инновационные решения.</li>
            <li className="winners-item"><strong>Лучший сериал:</strong> «Перекрестки судеб» (Армения) — за качественный сценарий и актерскую игру.</li>
            <li className="winners-item"><strong>Лучший сценарист:</strong> Сергей Иванов (Украина) — за сценарий к фильму «Туманный путь», впечатляющий своей глубиной.</li>
            <li className="winners-item"><strong>Лучший режиссер:</strong> Елена Ткаченко (Беларусь) — за мастерскую работу в фильме «Вихрь эмоций».</li>
            <li className="winners-item"><strong>Лучший композитор:</strong> Ольга Смирнова (Узбекистан) — за проникновенный музыкальный фон к сериалу «Память времен».</li>
            <li className="winners-item"><strong>Лучший продюсер:</strong> Дмитрий Павлов (Киргизия) — за успешное продюсирование фильма «На крыльях времени».</li>
          </ul>
        </div>

        <div className="winners-year-section">
          <h2 className="winners-year-title">2022</h2>
          <ul className="winners-list">
            <li className="winners-item"><strong>Лучший международный фильм:</strong> «Море и звезды» (Россия) — за художественное исполнение и международное признание.</li>
            <li className="winners-item"><strong>Лучший дебютный фильм:</strong> «Иной мир» — Павел Жданов (Беларусь) — за оригинальное режиссерское видение и техническое мастерство.</li>
            <li className="winners-item"><strong>Лучший актер:</strong> Андрей Карпов («Тени прошлого») (Таджикистан) — за выдающееся исполнение сложной роли.</li>
            <li className="winners-item"><strong>Лучшая актриса:</strong> Елизавета Андреева («Странник») (Россия) — за мастерство перевоплощения и глубину игры.</li>
            <li className="winners-item"><strong>Лучший телевизионный проект:</strong> «Голоса эпохи» (Россия) — за оригинальность и вклад в популяризацию культурного наследия.</li>
            <li className="winners-item"><strong>Лучший сериал:</strong> «Нить судьбы» (Молдова) — за увлекательный сюжет и качественное исполнение.</li>
            <li className="winners-item"><strong>Лучший сценарист:</strong> Михаил Серов (Казахстан) — за оригинальный сценарий к фильму «Свет в темноте».</li>
            <li className="winners-item"><strong>Лучший режиссер:</strong> Анна Борисова (Киргизия) — за режиссуру фильма «Грани реальности».</li>
            <li className="winners-item"><strong>Лучший композитор:</strong> Александр Власов (Армения) — за музыкальное оформление к фильму «Свет надежды».</li>
            <li className="winners-item"><strong>Лучший продюсер:</strong> Ирина Матвеева (Беларусь) — за успешное продюсирование сериала «Сквозь время».</li>
          </ul>
        </div>

        <div className="winners-year-section">
          <h2 className="winners-year-title">2023</h2>
          <ul className="winners-list">
            <li className="winners-item"><strong>Лучший международный фильм:</strong> «Огонь в сердце» (Казахстан) — за выдающееся художественное исполнение и международное признание.</li>
            <li className="winners-item"><strong>Лучший дебютный фильм:</strong> «Сила тишины» — Юрий Иванов (Россия) — за оригинальность и эмоциональную насыщенность.</li>
            <li className="winners-item"><strong>Лучший актер:</strong> Дмитрий Смирнов («Зов предков») (Россия) — за выдающуюся актерскую игру и эмоциональную глубину.</li>
            <li className="winners-item"><strong>Лучшая актриса:</strong> Анна Коваленко («На грани») (Беларусь) — за трогательное исполнение роли, привлекающее внимание зрителей.</li>
            <li className="winners-item"><strong>Лучший телевизионный проект:</strong> «Мост времен» (Молдова) — за высокий уровень продакшена и вклад в развитие телевидения.</li>
            <li className="winners-item"><strong>Лучший сериал:</strong> «Отражение жизни» (Азербайджан) — за целостность повествования и глубокие сюжетные линии.</li>
            <li className="winners-item"><strong>Лучший сценарист:</strong> Сергей Жуков (Россия) — за сценарий к фильму «Поворотный момент», отличающийся глубокими темами и оригинальностью.</li>
            <li className="winners-item"><strong>Лучший режиссер:</strong> Олег Захаров (Таджикистан) — за умелую режиссуру и новаторский подход в фильме «Нить времен».</li>
            <li className="winners-item"><strong>Лучший композитор:</strong> Николай Лебедев (Армения) — за оригинальный саундтрек к фильму «Ночной зов».</li>
            <li className="winners-item"><strong>Лучший продюсер:</strong> Елена Кузнецова (Киргизия) — за успешное продюсирование телевизионного проекта «Золотая осень».</li>
          </ul>
        </div>

        <div className="winners-year-section">
          <h2 className="winners-year-title">2024</h2>
          <ul className="winners-list">
            <li className="winners-item"><strong>Лучший международный фильм:</strong> «Волна надежды» (Азербайджан) — за уникальный культурный вклад и художественное исполнение.</li>
            <li className="winners-item"><strong>Лучший дебютный фильм:</strong> «Путь в темноте» — Екатерина Смирнова (Россия) — за эмоциональную насыщенность и техническое мастерство.</li>
            <li className="winners-item"><strong>Лучший актер:</strong> Максим Орлов («Сквозь огонь») (Беларусь) — за выдающееся исполнение главной роли.</li>
            <li className="winners-item"><strong>Лучшая актриса:</strong> Жанна Миронова («Тени прошлого») (Казахстан) — за сложную и многослойную актерскую работу.</li>
            <li className="winners-item"><strong>Лучший телевизионный проект:</strong> «Магия мира» (Киргизия) — за оригинальные идеи и вклад в развитие жанра.</li>
            <li className="winners-item"><strong>Лучший сериал:</strong> «Эхо дней» (Молдова) — за популярность и высокий уровень исполнения.</li>
            <li className="winners-item"><strong>Лучший сценарист:</strong> Андрей Новиков (Армения) — за сценарий к фильму «Ветер перемен», отличающийся творческим подходом.</li>
            <li className="winners-item"><strong>Лучший режиссер:</strong> Мария Захарова (Россия) — за выдающееся мастерство в режиссуре фильма «Тайна вечности».</li>
            <li className="winners-item"><strong>Лучший композитор:</strong> Алина Орлова (Беларусь) — за музыкальное сопровождение к сериалу «Звездные дороги».</li>
            <li className="winners-item"><strong>Лучший продюсер:</strong> Игорь Ковалев (Россия) — за успешное продюсирование телевизионного проекта «Перекрестки».</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default WinnersPage;
